import React, { useContext } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { AuthContext } from '../../providers/auth-provider';

export const VerifyToken = () => {
  const { loginfromTalkoot } = useContext(AuthContext);  
  const useQuery = () => {
      return new URLSearchParams(useLocation().search);
  }  
  let query = useQuery();
  const token = query.get('token');
  const refreshToken = query.get('refreshToken');
  const user = query.get('user');
  const brandIdIdp = query.get('brandId');
  
  loginfromTalkoot(token, refreshToken, user, brandIdIdp); 
  return <Redirect push to={'/'} />;
}
