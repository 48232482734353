import React, { useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import { AppContext } from '../../providers/app-provider';
import { useCustomersQuery } from '../../hooks/use-customers-query.hook';
import { useBrandCustomerQuery } from '../../hooks/use-brand-customer-query.hook';
import { useBrandDictionariesQuery } from '../../hooks/use-brand-dictionaries-query.hook';

export const CustomerSelect = () => {
  const {
    setBrandId,
    customerId,
    setCustomerId,
    setDictionaries,
    setIsLoadingCustomers,
    setViewTermsDictionary,
  } = useContext(AppContext);
  
  const { isLoadingBrandDictionaries } = useBrandDictionariesQuery();
  const { isLoadingCustomers, customers } = useCustomersQuery();
  const { brandCustomer } = useBrandCustomerQuery();

  useEffect(() => {
    if (Boolean(customers?.length)) {
      const customerId = parseInt(localStorage.getItem('currentCustomerId'));
      const currentBrandIdIdp = parseInt(localStorage.getItem('currentBrandIdIdp'));
      if (customerId) {
        setCustomerId(customerId);
      } else if (!currentBrandIdIdp) {
        setCustomerId(customers[0]['customerID']);
        localStorage.setItem('currentCustomerId', customers[0]['customerID']);
      }
    }
  }, [customers, setCustomerId]);

  useEffect(() => {
    setIsLoadingCustomers(isLoadingCustomers)
  }, [isLoadingCustomers]);

  useEffect(() => {
    if (brandCustomer?.customerID) {
      setCustomerId(brandCustomer?.customerID);
    }
  }, [brandCustomer, setCustomerId]);

  return (
    <div className="position-relative me-2">
      {customerId !== '0' &&
        <label
          className="position-absolute left-0 top-0 h-100 d-flex align-items-center ps-2 small text-secondary"
        >
          Organization
        </label>
      }
      <Form.Select
        type="select"
        name="customerId"
        value={customerId}
        className={`form-control ${customerId !== '0' && 'fw-bold'}`}
        onChange={(e) => {
          const selectedCustomerId = e.target.value;
          setCustomerId(selectedCustomerId);
          setBrandId('0');
          setDictionaries([]);
          setViewTermsDictionary({});
          localStorage.setItem('currentCustomerId', selectedCustomerId);
          localStorage.removeItem('currentBrandIdIdp');
        }}
        disabled={ isLoadingCustomers || isLoadingBrandDictionaries}
        style={{
          display: 'inline',
          width: '18rem',
          paddingLeft: customerId !== '0' ? '6rem' : '', 
        }}
      >
        <option value="0" disabled>Select an organization...</option>
        {customers && customers.map(customer => (
          <option key={customer.customerID} value={customer.customerID}>{customer.companyName}</option>))}
      </Form.Select>
    </div>
  );
};
