import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';
import 'flag-icon-css/css/flag-icons.min.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { flags } from '../../../constants/flags';
import { AppContext } from "../../../providers/app-provider";

import {
  getMainDictionaries,
  postBrandDictionary,
  deleteBrandDictionary,
} from "../../../services/dictionaries.service";

export const ManageMainDictionariesModal = ({ show, closeModal, onAdded, onDeleted }) => {
  const [ saving, setSaving ] = useState(false);
  const {
    brandId,
    dictionaries,
  } = useContext(AppContext);
  const [ selectedDictionaries, setSelectedDictionaries ] = useState([]);
  const [ mainDictionaries, setMainDictionaries ] = useState([]);

  const addBrandDictionary = useMutation(postBrandDictionary);

  const removeBrandDictionary = useMutation(deleteBrandDictionary);

  const handleChange = (dictionaryID, checked) => {
    setSaving(true);
    const dictionaryBrand = {
      ...mainDictionaries.filter(dictionary => dictionary.dictionaryID === dictionaryID)[0],
      brandId: parseInt(brandId),
    };
    if (checked) {
      setSelectedDictionaries([ ...selectedDictionaries, dictionaryID ]);
      addBrandDictionary.mutate(dictionaryBrand, {
        onSuccess: () => {
          Swal.fire({
            text: 'Language enabled successfully',
            icon: 'success',
            showConfirmButton: false,
           timer: 1500,
          });
          onAdded({
            ...dictionaryBrand,
            children: [],
          });
        },
        onError: () => {
          Swal.fire({
            text: 'Language was not enabled',
            icon: 'error'
          });
        },
        onSettled: () => {
          setSaving(false);
        },
      });
    } else {
      setSelectedDictionaries(selectedDictionaries.filter(dictionary => dictionary !== dictionaryID));
      removeBrandDictionary.mutate(dictionaryBrand, {
        onSuccess: () => {
          Swal.fire({
            text: 'Language disabled successfully',
            icon: 'success',
            showConfirmButton: false,
           timer: 1500,
          });
          onDeleted(dictionaryBrand);
        },
        onError: () => {
          Swal.fire({
            text: 'Language was not disabled',
            icon: 'error'
          });
        },
        onSettled: () => {
          setSaving(false);
        },
      });
    }
  };

  const hasChildrenDictionaries = (dictionaryID) => {
    return Boolean(dictionaries.filter(dictionary => dictionary.parentID === dictionaryID).length);
  };

  useEffect(() => {
    if (dictionaries.length) {
      const initialDictionaries = dictionaries.filter(dictionary => dictionary.parentID === null).map(dictionary => dictionary.dictionaryID);
      setSelectedDictionaries([...initialDictionaries]);
    }
  }, [dictionaries]);
  
  useEffect(() => {
    getMainDictionaries().then((response) => {
      if (response && response.data) {
        setMainDictionaries(response.data);
      }
    });
  }, []);

  return (
    <Modal show={show}>
      <ModalHeader className="fs-4">
        Manage Languages
        {saving && (
          <span className="fs-6">
            <span className="spinner-border spinner-border-sm text-primary mx-2" role="status" />
            Saving
          </span>
        )}
      </ModalHeader>
      <ModalBody className="ps-4">
        {mainDictionaries.sort((a, b) => a.dictionaryName.localeCompare(b.dictionaryName)).map(dictionary => (
          <div className="form-check form-switch" key={`checkbox-container-${dictionary.dictionaryID}`}>
            <input
              type="checkbox"
              id={`checkbox-${dictionary.dictionaryID}`}
              className="form-check-input cursor-pointer"
              checked={selectedDictionaries.includes(dictionary.dictionaryID)}
              onChange={(e) => handleChange(dictionary.dictionaryID, e.target.checked)}
              disabled={saving || hasChildrenDictionaries(dictionary.dictionaryID)}
            />
            <label className="form-check-label cursor-pointer small text-secondary" htmlFor={`checkbox-${dictionary.dictionaryID}`}>
              <span className={'me-2 flag-icon flag-icon-' + flags[dictionary.dictionaryCode]} style={{height: '15px'}}></span>
              {dictionary.dictionaryName}

            </label>
            {hasChildrenDictionaries(dictionary.dictionaryID) && (
                <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Languages that have been used cannot be disabled
                  </Tooltip>
                }
              >
                <i className="fa fa-info-circle text-secondary ms-2"></i>
              </OverlayTrigger>
              )}
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <button className="btn text-primary" onClick={closeModal} disabled={saving}>Close</button>
      </ModalFooter>
    </Modal>
  );
};
