import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useCustomerUsersQuery } from '../../../hooks/use-customer-users-query.hook';
import { useCustomersQuery } from '../../../hooks/use-customers-query.hook';

import { CustomersUsersLoading } from '../../common/customers-users-loading.component';
import { CustomerSelect } from '../../common/customer-select.component';
import { SearchInput } from '../../common/search-input.component';

import { AddUserModal } from './add-user-modal.component';
import { EditUserModal } from './edit-user-modal.component';
import { RemoveUserModal } from './remove-user-modal.component';

const valueCompare = (a, b, sortProperty) => {
  if (typeof a[sortProperty] == 'string') {
    return a[sortProperty].localeCompare(b[sortProperty]);
  }
  return a[sortProperty] - b[sortProperty];
};

const filterSortCustomerUsers = (customerUsers, searchTerm, sortValue) => {
  if (!customerUsers) {
    return []
  }

  let filteredSortedUsers = [];

  if (searchTerm) {
    filteredSortedUsers = customerUsers.filter(user => (
      user.userName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      && user.normalizedRoleName !== 'ADMIN'
    ));
  } else {
    filteredSortedUsers = customerUsers.filter(user => user.normalizedRoleName !== 'ADMIN');
  }

  if (sortValue) {
    const [sortProperty, sortDirection] = sortValue.split(',');
    if (sortProperty) {
      filteredSortedUsers.sort((a, b) => {
        if (sortDirection == 'asc') {
          return valueCompare(a, b, sortProperty);
        } else {
          return valueCompare(b, a, sortProperty);
        }
      });
    }
  }

  return filteredSortedUsers;
};

const TableColumnHeader = ({ title, center, className = '', sortProperty, sortValue, onSort }) => {
  const [hover, setHover] = useState(false);
  const [sort, setSort] = useState('');

  const handleClick = () => {
    let sortValue = '';
    switch (sort) {
      case '':
        sortValue = `${sortProperty},asc`;
        break;
      case `${sortProperty},asc`:
        sortValue = `${sortProperty},desc`;
        break;
      case `${sortProperty},desc`:
        sortValue = '';
        break;
    }
    setSort(sortValue);
    onSort(sortValue);
  }

  useEffect(() => {
    if (sortValue.includes(sortProperty)) {
      setSort(sortValue);
    } else {
      setSort('');
    }
  }, [sortValue]);

  return (
    <th
      className={`${className}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >
      <div className={`cursor-pointer d-flex align-items-center justify-content-${center ? 'center' : 'between'}`}>
        {title}
        <div className="d-flex flex-column text-tertiary fs-6 ms-2">
          <i className={`lh-0 fa fa-sort-asc ${sort == `${sortProperty},asc` ? 'text-secondary' : !hover && 'text-background'}`}></i>
          <i className={`lh-0 fa fa-sort-desc ${sort == `${sortProperty},desc` ? 'text-secondary' : !hover && 'text-background'}`}></i>
        </div>
      </div>
    </th>
  );
}

export const UsersTable = () => {
  const queryClient = useQueryClient();

  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [sortValue, setSortValue] = useState('userName,asc');

  const [selectedUser, setSelectedUser] = useState({
    user: '',
    userName: '',
    email: '',
    password: '',
    role: '',
    brandId: 0,
    passwordHash: '',
    activeFlag: true,
    normalizedRoleName: '',
  });

  const { isLoadingCustomers } = useCustomersQuery();
  const { isLoadingCustomerUsers, customerUsers } = useCustomerUsersQuery();

  const filteredSortedUsers = useMemo(() => filterSortCustomerUsers(customerUsers, searchTerm, sortValue), [customerUsers, searchTerm, sortValue]);

  const handleAction = (user, action) => {
    setSelectedUser(user);
    action(true)
  };

  return (
    <div className="card mx-auto shadow rounded" style={{maxWidth: '60rem'}}>
      <div className="d-flex border-bottom border-1">
        <div className="d-flex align-items-center p-3 flex-grow-1">
          <CustomerSelect />
          <CustomersUsersLoading />
        </div>
        <div className="d-flex align-items-center p-3">
          <SearchInput placeholder="Search user..." setSearchTerm={setSearchTerm} disabled={isLoadingCustomers || isLoadingCustomerUsers} />
          <button onClick={() => setAddUserModal(true)} className="btn btn-block btn-primary ms-3">
            <i className="fa fa-plus pe-2"></i>
            Add User
          </button>
        </div>
      </div>
      <div className="table-responsive" style={{height: 'calc(100vh - 12rem)'}}>
        <table className="table table-striped small" >
          <thead>
            <tr className="text-secondary">
              <TableColumnHeader title="Username" sortProperty="userName" className="px-3" sortValue={sortValue} onSort={setSortValue}/>
              <TableColumnHeader title="Email" sortProperty="email" sortValue={sortValue} onSort={setSortValue}/>
              <TableColumnHeader title="Role" sortProperty="roleName" sortValue={sortValue} onSort={setSortValue} center/>
              <TableColumnHeader title="Status" sortProperty="activeFlag" sortValue={sortValue} onSort={setSortValue} center/>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {customerUsers && filteredSortedUsers.map(user => (
              <tr key={user.id}>
                <td className="px-3">{user.userName}</td>
                <td>{user.email}</td>
                <td className="text-center">{user.normalizedRoleName}</td>
                <td className="text-center">
                  { user.activeFlag
                    ? <span className="badge rounded-pill bg-success">active</span>
                    : <span className="badge rounded-pill bg-danger">inactive</span>
                  }
                </td>
                <td className="text-center">
                  <button type="button" className="btn btn-sm text-primary" onClick={() => handleAction(user, setEditUserModal)}>
                    <i className="fa fa-edit"></i>
                  </button>
                  <button type="button" className="btn btn-sm text-primary" disabled={!user.activeFlag} onClick={() => handleAction(user, setRemoveUserModal)}>
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="small px-3 py-2 border-top border-1">{filteredSortedUsers.length} users</div>
      <AddUserModal show={addUserModal} closeModal={() => setAddUserModal(false)} onAccept={() => {
        queryClient.invalidateQueries('customerUsers');
        setAddUserModal(false);
      }} />                              
      <EditUserModal selectedUser={selectedUser}  show={editUserModal} closeModal={() => setEditUserModal(false)} onAccept={() => {
        queryClient.invalidateQueries('customerUsers');
        setEditUserModal(false);
      }} />
      <RemoveUserModal selectedUser={selectedUser}  show={removeUserModal} closeModal={() => setRemoveUserModal(false)} onAccept={() => {
        queryClient.invalidateQueries('customerUsers');
        setRemoveUserModal(false);
      }} />
    </div>
  );
};
