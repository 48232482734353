
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../providers/auth-provider';
import logo from '../../assets/brandcenter-logo.png';

import { UserProfileMenu } from '../common/user-profile-menu.component';
import { Role } from '../../constants/role';

export const NavBar = (props) => {
  const {
    user: {
      currentUser,
      username,
      role,
    },
    logout,
    displayName,
    loadingDisplayName,
  } = useContext(AuthContext);

  if (!currentUser) {
    return null;
  }

  return (
    <div className="navbar navbar-expand navbar-light bg-white shadow py-0">
      <div className="container-fluid d-flex">
        <a className="me-3 mt-1" href="/"><img src={logo} className="App-logo" alt="brand-center-logo" height="40" /></a>
        <div className="justify-content-between flex-grow-1 d-flex" id="navbar-list-8">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/dictionaries" className="nav-link" activeClassName="active">Dictionaries</NavLink>
            </li>
            {(role.includes(Role.Admin) || role.includes(Role.SuperUser)) && (
              <li className="nav-item">
                <NavLink to="/users" className="nav-link" activeClassName="active">Users</NavLink>
              </li>
            )}
          </ul>
          {!loadingDisplayName && <UserProfileMenu username={displayName || username} logout={logout} />}
        </div>
      </div>
    </div>
  )
};
