import axios from "axios";
import { Env } from "../constants/env";

const apiUrl = Env.BasePath;

export const getRoles = async (params) => {
  const response = await axios.get(apiUrl + 'brandcenter/GetRoles');
  if (response?.status !== 200) {
    throw Error('Problem fetching data');
  }
  return await response.data;
};
