import { useCustomerUsersQuery } from "../../hooks/use-customer-users-query.hook";
import { useCustomersQuery } from "../../hooks/use-customers-query.hook";

export const CustomersUsersLoading = () => {
  const { isLoadingCustomers } = useCustomersQuery();
  const { isLoadingCustomerUsers } = useCustomerUsersQuery();

  return (isLoadingCustomers || isLoadingCustomerUsers) && (
    <>
      <span className="spinner-border spinner-border-sm text-primary mx-2" role="status" />
      <span className="small text-secondary">Loading your {isLoadingCustomers ? 'organizations' : isLoadingCustomerUsers ? 'users' : ''}...</span>
    </>
  );
};
