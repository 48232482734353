import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { appInsights, reactPlugin } from './utils/app-insights';

import { AuthProvider } from './providers/auth-provider';
import { AppProvider } from './providers/app-provider';
import { jwtInterceptor } from './providers/jwt-interceptor';

import { Routes } from './routes';

import { NavBar } from './components/nav-bar/nav-bar.component';

import { Env } from './constants/env';

import { IntercomProvider  } from 'react-use-intercom';

import 'font-awesome/css/font-awesome.min.css';
import './style.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const intercomAppId = Env.IntercomAppId;

jwtInterceptor();
appInsights.loadAppInsights();

ReactDOM.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <IntercomProvider appId={intercomAppId}>
          <AuthProvider>
            <BrowserRouter basename={baseUrl}>
              <NavBar />
              <Routes />
            </BrowserRouter>
          </AuthProvider>
        </IntercomProvider>
      </AppProvider>
    </QueryClientProvider>
  </AppInsightsContext.Provider>,
  rootElement
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();

    if (caches) {
      caches.keys().then(async (names) => {
        await Promise.all(names.map(name => caches.delete(name)));
      });
    }
  });
}
