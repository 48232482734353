import React from 'react';
import { useMutation } from 'react-query';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { patchDictionary } from '../../../services/dictionaries.service';
import { flags } from '../../../constants/flags';
import 'flag-icon-css/css/flag-icons.min.css';

const validationSchema = Yup.object().shape({
  dictionaryName: Yup.string().required('Dictionary is required'),
});

export const EditDictionaryModal = ({ dictionary, show, closeModal, onAccept }) => {
  const appInsights = useAppInsightsContext();
  const editDictionary = useMutation(patchDictionary);

  const onSubmit = (values, { setSubmitting }) => {
    const dictionaryData =  { ...dictionary };
    dictionaryData.dictionaryName = values.dictionaryName;
    editDictionary.mutate(dictionaryData, {
      onSuccess: () => {
        Swal.fire({
          text: 'The dictionary was successfully edited.',
          icon: 'success',
          showConfirmButton: false,
         timer: 1500,
        });
        onAccept(dictionaryData);
      },
      onError: (error) => {
        Swal.fire({
          text: 'The dictionary could not be edited.',
          icon: 'error'
        });
        appInsights.trackException({ exception: error });
      },
      onSettled: ()  => {
        setSubmitting(false);
      },
    });
  };

  return (
    <Formik
      initialValues={{
        dictionaryID: dictionary.dictionaryID,
        dictionaryName: dictionary.dictionaryName,
        dictionaryCode: dictionary.dictionaryCode,
        parentID: dictionary.parentID,
        parentDictionaryName: dictionary.parentDictionaryName,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        handleChange,
        handleSubmit,
        isValid,
        values,
      }) => (
        <Modal show={show}>
          <ModalHeader>
            <span className="fs-4">Edit dictionary</span>
            <span className={'flag-icon flag-icon-' + flags[dictionary.dictionaryCode]} style={{width: '32px', height: '24px'}}></span>
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <div className="row mb-3">
                <div className="col">
                  {values.parentDictionaryName && (
                    <>
                     <label className="small text-secondary">Parent dictionary</label>
                     <Field name="parentDictionaryName" type="text" className="form-control" disabled />
                    </>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className="small text-secondary">Name</label>
                  <Field name="dictionaryName" type="text" className="form-control"  onChange={handleChange} disabled={isSubmitting} />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn text-primary" onClick={closeModal} disabled={isSubmitting}>Cancel</button>
            <button type="submit" className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={handleSubmit}>
              {isSubmitting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
              Save
            </button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  )
};
 