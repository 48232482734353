import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { postTerm, getTermTypes } from '../../../services/terms.service';

const validationSchema = Yup.object().shape({
  value: Yup.string().required(),
  termTypeID: Yup.string().required().notOneOf(['Select a term type...']),
});

export const AddTermsModal = ({ dictionary, show, closeModal, onAccept }) => {
  const appInsights = useAppInsightsContext();
  const [errorMessage, setErrorMessage] = useState([]);
  const addTerm = useMutation(postTerm);

  const { isLoading: isLoadingTermTypes, data: termTypes } = useQuery(
    ['termsTypes'],
    getTermTypes,
    {
      onError: (error) => {
        appInsights.trackException({ exception: error });
      },
    },
  );

  const onSubmit = (values, { setSubmitting }) => {
    let addedTerms = values.value.trim().split('\n');
    const uniqueterms = [];
    const errors = [];

    addedTerms.forEach(term => {
      if (uniqueterms.includes(term)) {
        errors.push(`"${term}" is repeated.`);
      } else {
        uniqueterms.push(term);
      }
    });

    if (errors.length > 0) {
      setErrorMessage(errors);
      setSubmitting(false);
      return;
    }

    const termData =  {
      dictionaryID: dictionary.dictionaryID,
      value: values.value.trim(),
      termTypeID: values.termTypeID,
    };
    setErrorMessage([]);

    addTerm.mutate(termData, {
      onSuccess: (response) => {
        const errors = response?.data?.errors;
        if (errors?.length > 0) {
          setErrorMessage(errors);
        } else {
          Swal.fire({
            text: 'Term(s) successfully added to the dictionary.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          });
          values.value = '';
          values.termTypeID = 'Select a term type...';
          onAccept(addedTerms);
        }
      },
      onError: (error) => {
        Swal.fire({
          text: 'The term(s) could not be added.',
          icon: 'error'
        });
        appInsights.trackException({ exception: error });
      },
      onSettled: ()  => {
        setSubmitting(false);
      },
    });
  };

  const onCancel = (values) => {
    values.value = '';
    values.termTypeID = 'Select a term type...';
    setErrorMessage([]);
    closeModal();
  };
  
  const handleSave = (values, handleSubmit) => {
    if(values.value === '' || values.termTypeID === 'Select a term type...'){
      return;
    }

    const selectedTermType = termTypes.filter(termType => termType.termTypeID === parseInt(values.termTypeID))[0];
    if (selectedTermType?.termTypeCode === 'SPELL') {
      const spellingTerms = values.value.trim().split('\n');
      for (let index = 0; index < spellingTerms.length; index++) {
        const value = spellingTerms[index];
        if (value.includes(' ')) {
          setErrorMessage(['"Spelling" type terms should be one word only.']);
          return;
        }
      }
      setErrorMessage([]);
    }
    handleSubmit();
  };

  return (
    <Formik
      initialValues={{
        value: '',
        termTypeID: 'Select a term type...',
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        handleChange,
        handleSubmit,
        isValid,
        values,
      }) => (
        <Modal show={show}>
          <ModalHeader>
            <span className="fs-4">Add term(s) to the dictionary</span>
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <div className="row mb-3">
                <div className="col">
                  <label className="small text-secondary">Values (separated by new lines)</label>
                  <Field name="value" as="textarea" rows={4} className="form-control"  onChange={handleChange} disabled={isSubmitting} />
                  <label className="small text-danger">{errorMessage.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}</label>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="small text-secondary">Term type</label>
                  <Field name="termTypeID" as="select" className="form-select" onChange={handleChange} disabled={isLoadingTermTypes || isSubmitting}>
                    <option value="Select a term type..." disabled>Select a term type...</option>
                    {termTypes && termTypes.map(termType => (
                      <option key={termType.termTypeID} value={termType.termTypeID} >{termType.termTypeName}</option>
                    ))}
                  </Field>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn text-primary" onClick={() => onCancel(values)} disabled={isSubmitting}>Cancel</button>
            <button type="submit" className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={() => handleSave(values, handleSubmit)}>
              {isSubmitting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
              Save
            </button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  )
};
