import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { AppContext } from '../providers/app-provider';
import { getCustomerByBrandIdIdp } from '../services/customers.service';

export const useBrandCustomerQuery = () => {
  const appInsights = useAppInsightsContext();
  const { customerId } = useContext(AppContext);  

  const { isLoading: isLoadingBrandCustomer, data: brandCustomer } = useQuery(
    'brandCustomer',
    getCustomerByBrandIdIdp,
    {
      retry: false,
      enabled: customerId === '0' && Boolean(parseInt(localStorage.getItem('currentBrandIdIdp'))),
      onError: (error) => {
        appInsights.trackException({ exception: error });
      },
    }
  );

  return { isLoadingBrandCustomer, brandCustomer };
};
