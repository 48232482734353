export const Env = {
    //BasePath: 'https://localhost:5001/',
    BasePath: 'https://apptest.brandcenter.ai/',
    //BasePath: 'https://app.brandcenter.ai/',
    //prod
    //APPLICATION_INSIGHTS: '1db245d0-9b0a-4177-9d6c-8a9fd6b1ffbc',
    //test
    APPLICATION_INSIGHTS: 'd0bb5a8a-c8d2-4cd8-868f-1bf218d130cc',
    IntercomAppId: 'j7p89ti6',
    IntercomAppVerificationSecret: "A_3vJJ3F0y7VL3yL1_7LwKTCoeuZYpBexxN8HTBl"
}