import axios from "axios";
import { Env } from "../constants/env";

const apiUrl = Env.BasePath;

export const getDictionariesByBrand = async (params) => {
  const [_, brandId] = params.queryKey;
  const response = await axios.get(apiUrl + 'brandcenter/GetDictionariesByBrand?brandId=' + brandId);
  return response.data;
};

export const postDictionary = (dictionary) => axios.post(apiUrl + 'brandcenter/SaveDictionary', dictionary);

export const patchDictionary = (dictionary) => axios.put(apiUrl+ 'brandcenter/UpdateDictionary', dictionary);

export const deleteDictionary = (dictionary) => axios.put(apiUrl + 'brandcenter/DeleteDictionary', dictionary);

export const syncDictionary = (dictionary) => axios.post(apiUrl + 'brandcenter/SyncDictionary', dictionary);

export const getMainDictionaries = () => axios.get(apiUrl + 'brandcenter/GetMainDictionaries');

export const postBrandDictionary = (dictionary) => axios.post(apiUrl + 'brandcenter/SaveBrandDictionary', dictionary);

export const deleteBrandDictionary = (dictionary) => axios.put(apiUrl+ 'brandcenter/DeleteBrandDictionary', dictionary);
