import React, { useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { AuthContext } from '../../providers/auth-provider';
import bclogo from '../../assets/brandcenter-logo.png';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

export const Login = (props) => {
  const { user, login } = useContext(AuthContext);
  const history = useHistory();

  if (user?.currentUser) {
    const { from } = props.location.state || {
      from: { pathname: '/dictionaries' },
    };
    return <Redirect push to={from} />;
  }

  const handleSubmit = ({ email, password }, { setStatus, setSubmitting }) => {
    setStatus();
    login(email, password).then(
      () => {
        const { from } = props.location.state || {
          from: { pathname: '/dictionaries' },
        };
        history.push(from);
      },
      (error) => {
        console.error({ error });
        setSubmitting(false);
        setStatus('Username or password incorrect.');
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, status, touched, isSubmitting }) => (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{ minHeight: '100vh' }}
        >
          <div className='w-100' style={{ maxWidth: '400px' }}>
            <div className='card border-0 rounded-3 overflow-hidden shadow'>
              <div className='text-center py-4 bg-primary'>
                <div className='login-logo'>
                  <img src={bclogo} alt='Brandcenter' width='300' />
                </div>
              </div>
              <Form className='p-3'>
                <div className='mb-3'>
                  <label className='small text-secondary' htmlFor='email'>
                    Username
                  </label>
                  <Field
                    name='email'
                    type='text'
                    className={
                      'form-control' +
                      (errors.email && touched.email ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='mb-3'>
                  <label className='small text-secondary' htmlFor='password'>
                    Password
                  </label>
                  <Field
                    name='password'
                    type='password'
                    className={
                      'form-control' +
                      (errors.password && touched.password ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='password'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='text-end mb-3'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={isSubmitting}
                    style={{ minWidth: '7rem' }}
                  >
                    {isSubmitting && (
                      <span
                        className='spinner-border spinner-border-sm me-2'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    )}
                    Login
                  </button>
                </div>
                {status && <div className={'alert alert-danger'}>{status}</div>}
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
