import axios from "axios";
import { Env } from "../constants/env";

const apiUrl = Env.BasePath;

export const getBrands = async () => {
  const response = await axios.get(apiUrl + 'brandcenter/getBrands');
  if (response?.status !== 200) {
    throw Error('Problem fetching data');
  }
  return await response.data;
};

export const getBrandsByCustomerIdAndUserName = async ({ queryKey }) => {
  const [, customerId] = queryKey;
  const userName = localStorage.getItem('currentUserName');
  const brandIdIdp = localStorage.getItem('currentBrandIdIdp') ?? 0;
  const response = await axios.get(apiUrl + `brandcenter/GetBrandsByCustomerIdAndUserName?customerId=${customerId}&userName=${userName}&brandIdIdp=${brandIdIdp}`);
  if (response?.status !== 200) {
    throw Error('Problem fetching data');
  }
  var data = await response.data;
  return data;
};
