import axios from "axios";
import { Env } from "../constants/env";

const apiUrl = Env.BasePath;

export const getTermTypes = async () => {
  const response = await axios.get(apiUrl + 'brandcenter/GetTermTypes');
  if (response?.status !== 200) {
    throw Error('Problem fetching data');
  }
  return await response.data;
};

export const getPaginatedTerms = async (params) => {
  const [, { dictionaryId, perPage, page, term, orderQuery, termId, includeParentTerms }] = params.queryKey;
  const response = await axios.get(apiUrl + `brandcenter/GetAllTermsByDictionaryWithPagination?dictionaryID=${dictionaryId}&pageSize=${perPage}&pageNumber=${page}&term=${term}&OrderBy=${orderQuery}&termTypeID=${termId}&includeParentTerms=${includeParentTerms}`);
  if (response?.status !== 200) {
    throw Error('Problem fetching data');
  }
  return await response.data;
};

export const postTerm = (term) => axios.post(apiUrl + 'brandcenter/SaveTerm', term);

export const patchTerm = (term) => axios.put(apiUrl+ 'brandcenter/UpdateTerm', term);

export const deleteTerm = (term) => axios.put(apiUrl+ 'brandcenter/DeleteTerm', term);
