import React, { useContext, useEffect, useState } from 'react';
import 'flag-icon-css/css/flag-icons.min.css';

import { AppContext } from '../../providers/app-provider';
import { AuthContext } from '../../providers/auth-provider';
import { useQueryClient } from 'react-query';

import { ManageMainDictionariesModal } from './components/manage-main-dictionaries-modal.component';
import { AddChildDictionaryModal } from './components/add-child-dictionary-modal.component';
import { SyncDictionaryModal } from './components/sync-dictionary-modal.component';
import { EditDictionaryModal } from './components/edit-dictionary-modal.component';
import { RemoveDictionaryModal } from './components/remove-dictionary-modal.component';
import { DictionariesTree } from './components/dictionaries-tree.component';
import { TermsTable } from '../terms/components/terms-table.component';

import { createDictionaryTree } from '../../utils/create-dictionary-tree';
import { CustomerSelect } from '../common/customer-select.component';
import { CustomerBrandSelect } from '../common/customer-brand-select.component';
import { useBrandDictionariesQuery } from '../../hooks/use-brand-dictionaries-query.hook';
import { Role } from '../../constants/role';

export const DictionariesPage = () => {
  const queryClient = useQueryClient();

  const [ selectedDictionary, setSelectedDictionary ] = useState({});
  const [ addChildDictionaryModal, setAddChildDictionaryModal] = useState(false);
  const [ syncDictionaryModal, setSyncDictionaryModal] = useState(false);
  const [ editDictionaryModal, setEditDictionaryModal ] = useState(false);
  const [ removeDictionaryModal, setRemoveDictionaryModal ] = useState(false);
  const [ manageMainDictionariesModal, setManageMainDictionariesModal ] = useState(false);
  const {
    brandId,
    dictionaries,
    setDictionaries,
    isLoadingCustomers,
    isLoadingCustomerBrands,
    setViewTermsDictionary,
  } = useContext(AppContext);
  
  const { user } = useContext(AuthContext);

  const { isLoadingBrandDictionaries } = useBrandDictionariesQuery();

  const handleAdd = (dictionary) => {
    setSelectedDictionary(dictionary);
    setAddChildDictionaryModal(true);
   };

  const handleSync = (dictionary) => {
    setSelectedDictionary(dictionary);
    setViewTermsDictionary({});
    setSyncDictionaryModal(true);
   };
  
  const handleEdit = (dictionary) => {
    setSelectedDictionary(dictionary);
    setEditDictionaryModal(true);
  };

  const handleRemove = (dictionary) => {
    setSelectedDictionary(dictionary);
    setViewTermsDictionary({});
    setRemoveDictionaryModal(true);
  };

  const handleViewTerms = (dictionary) => {
    setViewTermsDictionary(dictionary);
  };

  const handleAddChildDictionary = (newDic) => {
    setAddChildDictionaryModal(false);
    setDictionaries([...dictionaries, newDic]);
    queryClient.invalidateQueries('brandDictionaries');
    };

  const handleSyncDictionary = (syncDic) => {
    setSyncDictionaryModal(false);
    setDictionaries(dictionaries.filter(dic => dic.dictionaryID !== syncDic.dictionaryID));
    queryClient.invalidateQueries('brandDictionaries');
    };

  const handleEditDictionary = (updatedDic) => {
    setEditDictionaryModal(false);
    setDictionaries(dictionaries.map(dic => {
      if (dic.dictionaryID === updatedDic.dictionaryID) {
        return updatedDic;
      }
      return dic;
    }));
    setViewTermsDictionary(updatedDic);
    queryClient.invalidateQueries('brandDictionaries');
  };

  const handleRemoveDictionary = (removeDic) => {
    setRemoveDictionaryModal(false);
    setDictionaries(dictionaries.filter(dic => dic.dictionaryID !== removeDic.dictionaryID));
    queryClient.invalidateQueries('brandDictionaries');
  };
  
  const handleManageMainDictionariesAdded = (newDic) => {
    setDictionaries([...dictionaries, newDic]);
    queryClient.invalidateQueries('brandDictionaries');
  };

  const handleManageMainDictionariesDeleted = (removeDic) => {
    setDictionaries(dictionaries.filter(dic => dic.dictionaryID !== removeDic.dictionaryID));
    queryClient.invalidateQueries('brandDictionaries');
  };

  return (
    <div className="container-fluid p-3">
      <div className="card mx-auto shadow rounded" style={{ maxWidth: '80rem' }}>
        <div className="d-flex border-bottom border-1">
          <div className="d-flex align-items-center p-3 flex-grow-1">
            <CustomerSelect />
            <CustomerBrandSelect />
            {(isLoadingCustomerBrands || isLoadingCustomers) && (
              <>
                <span className="spinner-border spinner-border-sm text-primary mx-2" role="status" />
                <span className="small text-secondary">Loading your {isLoadingCustomers ? 'organizations' : isLoadingCustomerBrands ? 'brands' : ''}...</span>
              </>
            )}
          </div>
          {!user.role.includes(Role.ReadOnly) &&
            <div className="p-3">
              <button className="btn btn-block btn-primary-comp text-dark" onClick={() => setManageMainDictionariesModal(true)} disabled={Boolean(!parseInt(brandId)) || isLoadingBrandDictionaries}>
                <i className="fa fa-language fs-5 me-2"></i>
                Manage Languages
              </button>
            </div>}
        </div>
        <div className="row gx-0">
          <div className="col-4">
            <div className="p-3 overflow-auto border-end border-1" style={{ height: 'calc(100vh - 9.5rem)' }}>
              <DictionariesTree key={0} dictionaries={createDictionaryTree(dictionaries)} onRemove={handleRemove} onAdd={handleAdd} onSync={handleSync} onEdit={handleEdit} onViewTerms={handleViewTerms} />
            </div>
          </div>
          <div className="col-8">
            <TermsTable />
          </div>
        </div>
      </div>
      <AddChildDictionaryModal dictionary={selectedDictionary} show={addChildDictionaryModal} closeModal={() => setAddChildDictionaryModal(false)} onAccept={handleAddChildDictionary} />
      <SyncDictionaryModal dictionary={selectedDictionary} show={syncDictionaryModal} closeModal={() => setSyncDictionaryModal(false)} onAccept={handleSyncDictionary} />
      <EditDictionaryModal dictionary={selectedDictionary} show={editDictionaryModal} closeModal={() => setEditDictionaryModal(false)} onAccept={handleEditDictionary} />
      <RemoveDictionaryModal dictionary={selectedDictionary} show={removeDictionaryModal} closeModal={() => setRemoveDictionaryModal(false)} onAccept={handleRemoveDictionary} />
      <ManageMainDictionariesModal show={manageMainDictionariesModal} closeModal={() => setManageMainDictionariesModal(false)} onAdded={handleManageMainDictionariesAdded} onDeleted={handleManageMainDictionariesDeleted} />
    </div>
  )
};
