import React, { useState } from 'react';

export const AppContext = React.createContext({});

export const AppProvider = ({ children }) => {
  const [ brandId, setBrandId ] = useState('0');
  const [ customerId, setCustomerId ] = useState('0');
  const [ dictionaries, setDictionaries ] = useState([]);
  const [ viewTermsDictionary, setViewTermsDictionary ] = useState({});

  const [ isLoadingCustomers, setIsLoadingCustomers ] = useState(false);
  const [ isLoadingCustomerBrands, setIsLoadingCustomerBrands ] = useState(false);

  const clearAppContext = () => {
    setBrandId('0');
    setDictionaries([]);
    setViewTermsDictionary({});
  };

  const appContext = {
    brandId,
    setBrandId,
    customerId,
    setCustomerId,
    dictionaries,
    setDictionaries,
    isLoadingCustomers,
    setIsLoadingCustomers,
    isLoadingCustomerBrands,
    setIsLoadingCustomerBrands,
    viewTermsDictionary,
    setViewTermsDictionary,
    clearAppContext,
  };

  return (
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  );
}