import React, { useState } from 'react';
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { deleteUser } from '../../../services/users.service';

export const RemoveUserModal = ({ selectedUser, show, closeModal, onAccept }) => {
  const [ deleting, setDeleting ] = useState(false); 

  const removeUser = useMutation(deleteUser);

  const handleDelete = () => {
    setDeleting(true);
    removeUser.mutate(selectedUser, {
      onSuccess: (response) => {
        const success = response?.data;
        if (success) {
          Swal.fire({
            text: 'User removed successfully',
            icon: 'success',
            showConfirmButton: false,
           timer: 1500,
          });
          onAccept();
        } else {
          throw Error();
        }
      },
      onError: () => {
        Swal.fire({
          text: 'User was not removed',
          icon: 'error'
        });
      },
      onSettled: () => {
        setDeleting(false);
      },
    });
  };

  return (
    <Modal show={show}>
      <ModalHeader className="fs-4">
        Remove User
      </ModalHeader>
      <ModalBody>
        Are you sure to remove <strong>{selectedUser && selectedUser.userName}</strong>?
      </ModalBody>
      <ModalFooter>
        <button className="btn text-primary" onClick={closeModal} disabled={deleting}>No</button>
        <button className="btn btn-primary" onClick={handleDelete} disabled={deleting}>
          {deleting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};
