import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Env } from '../../../constants/env';
import { AppContext } from '../../../providers/app-provider';
import { AddTermsModal } from './add-terms-modal.component';
import { EditTermModal } from './edit-term-modal.component';
import { RemoveTermModal } from './remove-term-modal.component';
import { SearchInput } from '../../common/search-input.component';
import { flags } from '../../../constants/flags';
import 'flag-icon-css/css/flag-icons.min.css';
import { AuthContext } from '../../../providers/auth-provider';
import { Role } from '../../../constants/role';

const apiUrl = Env.BasePath;

const returnTermType = (id) => {
  switch(id) {
    case 1:
      return 'Brand';
    case 2:
      return 'Spelling';
    case 3:
      return 'Banned';
    default:
      return;
  }
};

export const TermsTable = () => {
  const {
    dictionaries,
    setDictionaries,
    viewTermsDictionary
  } = useContext(AppContext);
  
  const { user } = useContext(AuthContext);

  const [ addTermsModal, setAddTermsModal ] = useState(false);
  const [ editTermModal, setEditTermModal ] = useState(false);
  const [ removeTermModal, setRemoveTermModal ] = useState(false);

  const [ data, setData ] = useState([]);
  const [ termTypes, setTermTypes ] = useState([]);

  const [ searchTerm, setSearchTerm ] = useState('');
  const [ page, setPage ] = useState(1);
  const [ perPage, setPerPage ] = useState(10);
  const [ orderQuery, setOrderQuery ] = useState('');
  const [ termType, setTermType ] = useState(0);
  const [ totalRows, setTotalRows ] = useState(0);
  const [ resetPaginationToggle, setResetPaginationToggle ] = useState(false);
  const [ includeParentTerms, setIncludeParentTerms ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const [ selectedTerm, setSelectedTerm ] = useState({
    termID: 0,
    value: '',
    dictionaryID: 0,
    termTypeID: -1
  });

  const { dictionaryID, dictionaryCode, dictionaryName, termsCount } = viewTermsDictionary;
  const columns = [
    {
      name: 'termID',
      selector: row => row.termID, 
      omit: true,
      conditionalCellStyles: [
          {
              when: row => viewTermsDictionary?.dictionaryID != row?.dictionaryID,
              style: {
                    backgroundColor: 'rgba(230,230,230,1)',
              }
          }
      ]
    },
    {
      name: 'Term',
      selector: row => row.value,
      sortable: true,
      sortfield: 'Value',
      conditionalCellStyles: [
        {
            when: row => viewTermsDictionary?.dictionaryID != row?.dictionaryID,
            style: {
                  backgroundColor: 'rgba(230,230,230,1)',
            }
        }
      ]
    },
    {
      name: 'Type',
      selector: row => returnTermType(row.termTypeID),
      sortable: true,
      sortfield: 'TermTypeID',
      conditionalCellStyles: [
        {
            when: row => viewTermsDictionary?.dictionaryID != row?.dictionaryID,
            style: {
                  backgroundColor: 'rgba(230,230,230,1)',
            }
        }
      ]
    },
    {
      name: 'Actions',
      center: true,
      conditionalCellStyles: [
        {
            when: row => viewTermsDictionary?.dictionaryID != row?.dictionaryID,
            style: {
                  backgroundColor: 'rgba(230,230,230,1)',
            }
        }
      ],
      cell: row => (
        <div className="text-center">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                Edit term
              </Tooltip>
            }
          >
            <button className="btn btn-sm text-primary" onClick={()=> selectTerm(row, "Edit")} disabled={viewTermsDictionary?.dictionaryID != row?.dictionaryID} >
              <span className="fa fa-edit"></span>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                Delete term
              </Tooltip>
            }
          >
            <button className="btn btn-sm text-primary" onClick={()=> selectTerm(row, "Delete")} disabled={viewTermsDictionary?.dictionaryID != row?.dictionaryID}>
              <span className="fa fa-trash"></span>
            </button>
          </OverlayTrigger>
        </div>
      )
    },
  ];
  
  const selectTerm = (term, state)=> {
    setSelectedTerm(term);
    if(state === "Edit") setEditTermModal(true);
    if(state === "Delete") setRemoveTermModal(true);
  };

  const getTermTypes = async() => {
    await axios.get(apiUrl + 'brandcenter/GetTermTypes')
    .then(response => {
      setTermTypes(response.data);
    }).catch(error => {
      console.error(error);
    })
  };

  const getPaginatedTerms = useCallback(async () => {
    setIsLoading(true);
    await axios.get(apiUrl + `brandcenter/GetAllTermsByDictionaryWithPagination?dictionaryID=${viewTermsDictionary?.dictionaryID}&pageSize=${perPage}&pageNumber=${page}&term=${searchTerm}&OrderBy=${orderQuery}&termTypeID=${termType}&includeParentTerms=${includeParentTerms}`)
    .then(response => {
      const { terms, pagination } = response.data;
      setData(terms);
      setTotalRows(pagination.totalCount);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [orderQuery, page, perPage, searchTerm, termType, viewTermsDictionary, includeParentTerms]);

  const updateDictionariesTermsCount = (newTermsCount) => {
    setDictionaries(dictionaries.map(dictionary => {
      if (dictionary.dictionaryID === dictionaryID) {
        dictionary.termsCount = newTermsCount;
      }
      return dictionary;
    }));
  };

  const flag = useMemo(() => flags(dictionaryCode), [dictionaryCode]);

  useEffect(() => {
    if (viewTermsDictionary?.dictionaryID) {
      getPaginatedTerms();
    } else {
      setData([]);
    }
  }, [getPaginatedTerms, page, viewTermsDictionary, orderQuery, perPage, searchTerm, termType, includeParentTerms]);
  
  const ref = useRef();
  const [ termsTableHeight, setTermsTableHeight] = useState(0);

  useEffect(() => {
    getTermTypes();
    setTermsTableHeight(ref.current?.clientHeight);
    window.addEventListener('resize', () => {
      setTermsTableHeight(ref.current?.clientHeight);
    });
  }, []);

  return (
    <div>
      <div className={`d-flex justify-content-between p-3 pb-0 ${viewTermsDictionary?.dictionaryID && 'bg-primary-light'}`}>
        <div className="fs-4">
          {viewTermsDictionary?.dictionaryName ? dictionaryName : 'Terms'}
        </div>
        <div className="d-flex align-items-center">
          <span className={`flag-icon flag-icon-${flag}`} style={{width: '32px', height: '24px'}}></span>
        </div>
      </div>
      <div className={`p-3 border-bottom border-1 ${viewTermsDictionary?.dictionaryID && 'bg-primary-light'}`}>
        <div className='d-flex mb-3'>
          <div className="d-flex flex-grow-1 align-items-center">
            {Boolean(termTypes.length) && (
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="allRadioOption"
                  className="form-check-input cursor-pointer"
                  checked={termType === 0}
                  onChange={() => setTermType(0)}
                  disabled={!viewTermsDictionary?.dictionaryID}
                />
                <label className="form-check-label cursor-pointer small text-secondary" htmlFor="allRadioOption">All types</label>
              </div>
            )}
            { termTypes.map(type => (
              <div key={type.termTypeID} className="form-check form-check-inline">
                <input
                  type="radio"
                  id={type.termTypeID}
                  className="form-check-input cursor-pointer"
                  checked={termType === type.termTypeID}
                  onChange={() => setTermType(type.termTypeID)}
                  disabled={!viewTermsDictionary?.dictionaryID}
                />
                <label className="form-check-label cursor-pointer small text-secondary" htmlFor={type.termTypeID}>{type.termTypeName}</label>
              </div>
            ))}
          </div>
          <label className="form-check-label cursor-pointer small text-secondary" htmlFor="flexSwitchCheckChecked">Show parent terms</label>
          <div className="form-check form-switch ms-2 px-0">
            <input
              type="checkbox"
              id="flexSwitchCheckChecked"
              className="form-check-input mx-0 cursor-pointer"
              checked={includeParentTerms}
              onChange={() => {
                  setIncludeParentTerms(v => !v);
                  setResetPaginationToggle(true);
                  setPage(1);
                }}
              />
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <SearchInput placeholder="Search term..." setSearchTerm={setSearchTerm} disabled={!viewTermsDictionary?.dictionaryID}/>
          {!user.role.includes(Role.ReadOnly) &&
            <button className="btn btn-primary" onClick={() => setAddTermsModal(true)} disabled={!viewTermsDictionary?.dictionaryID}>
              <i className="fa fa-plus pe-2"></i>
              Add
            </button>
          }
        </div>
      </div>
      <div ref={ref} className="overflow-hidden" style={{height: 'calc(100vh - 21rem)'}}>
        <DataTable
          columns={user.role.includes(Role.ReadOnly) ? columns.slice(0, -1) : columns}
          data={data}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
          onChangePage={(page) => {
            setResetPaginationToggle(false);
            setPage(page);
          }}
          paginationResetDefaultPage={resetPaginationToggle}
          onSort={(column, sortDirection) => setOrderQuery(`${column.sortfield} ${sortDirection}`)}
          fixedHeader={true}
          fixedHeaderScrollHeight={`${ termsTableHeight - 60}px`}
          noDataComponent={
            <div className="m-3">
              <span className="text-secondary small">
                {!Boolean(viewTermsDictionary?.dictionaryID) ? 'Select a dictionary to see its terms' : 'There are no terms to display' }
              </span>
            </div>
          }
          progressComponent={
            <div className="m-3">
              <span className="spinner-border spinner-border-sm text-primary mx-2"></span>
              <span className="text-secondary small">Loading dictionary terms...</span>
            </div>
          }
          sortServer
          striped
        />
      </div>
      <AddTermsModal dictionary={viewTermsDictionary} show={addTermsModal} closeModal={() => setAddTermsModal(false)} onAccept={async (addedTerms) => {
        setAddTermsModal(false);
        viewTermsDictionary.termsCount = viewTermsDictionary.termsCount + addedTerms.length;
        updateDictionariesTermsCount(viewTermsDictionary.termsCount);
        getPaginatedTerms();
      }} />                              
      <EditTermModal term={selectedTerm}  show={editTermModal} closeModal={() => setEditTermModal(false)} onAccept={() => {
        setEditTermModal(false);
        getPaginatedTerms();
      }} />
      <RemoveTermModal term={selectedTerm}  show={removeTermModal} closeModal={() => setRemoveTermModal(false)} onAccept={async () => {
        setRemoveTermModal(false);
        viewTermsDictionary.termsCount = viewTermsDictionary.termsCount - 1;
        updateDictionariesTermsCount(viewTermsDictionary.termsCount);
        getPaginatedTerms();
      }} />
    </div>
  );
};
