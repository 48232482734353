import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { patchTerm } from '../../../services/terms.service';

const validationSchema = Yup.object().shape({
  termID: Yup.string(),
  value: Yup.string(),
});

export const EditTermModal = ({ term, show, closeModal, onAccept }) => {
  const appInsights = useAppInsightsContext();
  const [errorMessage, setErrorMessage] = useState([]);
  const editTerm = useMutation(patchTerm);

  const onSubmit = (values, { setSubmitting }) => {
    const termData =  { ...term, ...values };
    editTerm.mutate(termData, {
      onSuccess: (response) => {
        const errors = response?.data?.errors;
        if (errors?.length > 0) {
          setErrorMessage(errors);
        } else {
          Swal.fire({
            text: 'The term was successfully edited.',
            icon: 'success',
            showConfirmButton: false,
          timer: 1500,
          });
          setErrorMessage([]);
          onAccept();
        }
      },
      onError: (error) => {
        Swal.fire({
          text: 'The term could not be edited.',
          icon: 'error'
        });
        appInsights.trackException({ exception: error });
      },
      onSettled: ()  => {
        setSubmitting(false);
      },
    });
  };

  const onCancel = () => {
    setErrorMessage([]);
    closeModal();
  };

  return (
    <Formik
      initialValues={{
        termID: term.termID,
        value: term.value,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        handleChange,
        handleSubmit,
        isValid,
      }) => (
        <Modal show={show}>
          <ModalHeader>
            <span className="fs-4">Edit term</span>
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <div className="row mb-3">
                <div className="col">
                  <label className="small text-secondary">Value</label>
                  <Field name="value" type="text" className="form-control"  onChange={handleChange} disabled={isSubmitting} />
                  <label className="small text-danger">{errorMessage.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}</label>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn text-primary" onClick={onCancel} disabled={isSubmitting}>Cancel</button>
            <button type="submit" className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={handleSubmit}>
              {isSubmitting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
              Save
            </button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  )
};
