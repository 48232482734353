import axios from 'axios';
import { Env } from '../constants/env';

const apiUrl = Env.BasePath;

export const getUser = async (userName) =>
  axios.get(apiUrl + 'brandcenter/GetUser?userName=' + userName);

export const getUsersByCustomer = async ({ queryKey }) => {
  const [_, customerId] = queryKey;
  const response = await axios.get(
    apiUrl + 'brandcenter/GetUsers?customerId=' + customerId
  );
  if (response?.status !== 200) {
    throw Error('Problem fetching data');
  }
  return await response.data;
};

export const postUser = (user) =>
  axios.post(apiUrl + 'brandcenter/CreateUser', user);

export const patchUser = (user) =>
  axios.put(apiUrl + 'brandcenter/UpdateUser', user);

export const deleteUser = (user) =>
  axios.put(apiUrl + 'brandcenter/DeleteUser', user);
