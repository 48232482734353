import axios from "axios";
import { Env } from "../constants/env";

const apiUrl = Env.BasePath;

export const getCustomers = async () => {
  const response = await axios.get(apiUrl + 'brandcenter/GetCustomers')
  if (response?.status !== 200) {
    throw Error('Problem fetching data');
  }
  return await response.data;
};

export const getCustomerByBrandIdIdp = async () => {
  const brandIdIdp = localStorage.getItem('currentBrandIdIdp');
  const response = await axios.get(apiUrl + 'brandcenter/GetCustomerByBrandId?brandId=' + brandIdIdp);
  if (response?.status !== 200) {
    throw Error('Problem fetching data');
  }
  return await response.data;
};


export const getCustomersByUserName = async () => {
  const userName = localStorage.getItem('currentUserName');
  const response = await axios.get(apiUrl + 'brandcenter/GetCustomersByUserName?userName=' + userName);
  if (response?.status !== 200) {
    throw Error('Problem fetching data');
  }
  return await response.data;
};

export const patchCustomerUserAndBrand = (user) => axios.put(apiUrl+ 'brandcenter/UpdateCustomerUserAndBrand?id='+ user.id + 
"&role="+user.role+"&userName="+user.userName, user)
