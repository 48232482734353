import React from 'react';
import { UsersTable } from "./components/users-table.component";

export const UsersPage = () => {
  return (
    <div className="container-fluid p-3">
      <UsersTable />
    </div>
  );
};
