import { useQuery } from 'react-query';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { getCustomersByUserName } from '../services/customers.service';

export const useCustomersQuery = () => {
  const appInsights = useAppInsightsContext();

  const { isLoading: isLoadingCustomers, data: customers } = useQuery(
    'customers',
    getCustomersByUserName,
    {
      retry: false,
      onError: (error) => {
        appInsights.trackException({ exception: error });
      },
    }
  );

  return { isLoadingCustomers, customers };
};
