import React, { useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import { AppContext } from '../../providers/app-provider';
import { useCustomerBrandsQuery } from '../../hooks/use-customer-brands-query.hook';
import { useBrandDictionariesQuery } from '../../hooks/use-brand-dictionaries-query.hook';

export const CustomerBrandSelect = () => {
  const {
    brandId,
    setBrandId,
    customerId,
    setCustomerId,
    setIsLoadingCustomerBrands,
    setViewTermsDictionary,
  } = useContext(AppContext);
  
  const { isLoadingCustomerBrands, customerBrands } = useCustomerBrandsQuery();
  const { isLoadingBrandDictionaries } = useBrandDictionariesQuery();

  useEffect(() => {
    if (Boolean(customerBrands?.length)) {
      const currentBrandIdIdp = parseInt(localStorage.getItem('currentBrandIdIdp'));
      if (currentBrandIdIdp) {
        const brands = customerBrands.filter(brand => brand.brandIdIdp === currentBrandIdIdp);
        if (Boolean(brands?.length)) {
          setBrandId(brands[0]['brandID']);
          setCustomerId(brands[0]['customerID']);
          localStorage.setItem('currentCustomerId', brands[0]['customerID']);
        }
      } else if (customerId === '0') {
        setBrandId(customerBrands[0]['brandID']);
        setCustomerId(customerBrands[0]['customerID']);
        localStorage.setItem('currentBrandIdIdp', customerBrands[0]['brandID']);
        localStorage.setItem('currentCustomerId', customerBrands[0]['customerID']);
      }
    }
  }, [customerBrands, setBrandId, setCustomerId, customerId]);

  useEffect(() => {
    setIsLoadingCustomerBrands(isLoadingCustomerBrands)
  }, [isLoadingCustomerBrands]);

  return (
    <div className="position-relative">
      {brandId !== '0' &&
        <label
          className="position-absolute left-0 top-0 h-100 d-flex align-items-center ps-2 small text-secondary"
        >
          Brand
        </label>
      }
      <Form.Select
        type="select"
        name="brandId"
        value={brandId}
        className={`form-control ${brandId !== '0' && 'fw-bold'}`}
        onChange={(e) => {
          const selectedBrandId = e.target.value;
          setBrandId(selectedBrandId);
          setViewTermsDictionary({});
          const brands = customerBrands.filter(brand => brand.brandID === parseInt(selectedBrandId));
          localStorage.setItem('currentBrandIdIdp', brands[0]['brandIdIdp']);
        }}
        disabled={isLoadingCustomerBrands || isLoadingBrandDictionaries || !Boolean(customerBrands?.length)}
        style={{
          display: 'inline',
          width: '18rem',
          paddingLeft: brandId !== '0' ? '3.2rem' : '', 
        }}
      >
        <option value='0' disabled>Select a brand...</option>
        {customerBrands && customerBrands.map(brand => (
          <option key={brand.brandID} value={brand.brandID}>{brand.brandName}</option>))}
      </Form.Select>
    </div>
  );
};
