import React, { useContext, useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';

import { AppContext } from '../../../providers/app-provider';
import { AuthContext } from '../../../providers/auth-provider';
import { flags } from '../../../constants/flags';
import { useBrandDictionariesQuery } from '../../../hooks/use-brand-dictionaries-query.hook';
import 'flag-icon-css/css/flag-icons.min.css';
import { Role } from '../../../constants/role';

const FlagIcon = ({ code }) => {
  const flag = useMemo(() => flags(code), [code]);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{code}</Tooltip>}
    >
      <span className={`me-2 flag-icon flag-icon-${flag}`} style={{height: '15px'}}></span>
    </OverlayTrigger>
  );
}

const DictionaryLeaf = ({dictionary, onRemove, onAdd, onSync, onEdit, onViewTerms }) => {
  const { viewTermsDictionary } = useContext(AppContext);
  const { user } = useContext(AuthContext);

  const hasChildren = Boolean(dictionary.children && dictionary.children.length);
  const isLanguageDictionary = Boolean(!dictionary.parentID);
  const isActiveDictionary = Boolean(viewTermsDictionary && viewTermsDictionary.dictionaryID === dictionary.dictionaryID);
  const selectedStatusColor = isActiveDictionary ? 'primary' : 'tertiary';

  const handleAction = (dictionary, onAction) => {
    document.body.click();
    onAction(dictionary);
  };

  const preventClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div id={dictionary.dictionaryID} key={dictionary.dictionaryID} className={dictionary.parentID && 'ms-4'}>
      <div className={`d-flex rounded mb-2 ${isLanguageDictionary ? 'bg-background-light' : 'bg-white'} overflow-hidden position-relative`}>
        {isLanguageDictionary && (
          <>
            <div className="d-flex align-items-center justify-content-center bg-primary-comp" style={{width: '3rem'}}>
              <i className="fa fa-language fs-5"></i>
            </div>
            <div className="d-flex flex-fill rounded-end">
            <div className="flex-fill">
                <div className={`d-flex align-items-center px-2 py-1 w-100 text-start`}>
                  <FlagIcon code={dictionary.dictionaryCode} />
                  <span className="fw-bold flex-grow-1 fs-7">
                    {dictionary.dictionaryName}
                  </span>
                  {!user.role.includes(Role.ReadOnly) &&
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          Add child dictionary
                        </Tooltip>
                      }
                    >
                      <div
                        key={`options-${dictionary.dictionaryID}`}
                        className="d-flex align-items-center justify-content-center rounded-circle text-primary hover-darker"
                        style={{width:'2rem', height:'2rem'}}
                        onClick={() => handleAction(dictionary, onAdd)}
                      >
                        <i className="fa fa-plus"></i>
                      </div>
                    </OverlayTrigger>
                  }
                </div>
              </div>
            </div>
          </>
        )}
        {!isLanguageDictionary && (
          <>
            <div className={`border-start border-5 border-${selectedStatusColor}`}></div>
            <div className={`d-flex flex-fill border rounded-end hover-dark ${isActiveDictionary && 'bg-primary-light'}`} onClick={() => {
              if (!isLanguageDictionary) {
                onViewTerms(dictionary);
              }
            }}>
              {/* {remove d-none below for dictionary profile pic support} */}
              <div className="d-flex align-items-center ps-2 d-none">
                <span className="bg-background rounded-circle" style={{height: '2.5rem', width: '2.5rem'}}></span>
              </div>
              <div className="flex-fill">
                <div className={`d-flex align-items-center px-2 w-100 text-start`}>
                  <span className="fw-bold flex-grow-1">
                    {dictionary.dictionaryName}
                  </span>
                  {!user.role.includes(Role.ReadOnly) &&
                    <OverlayTrigger
                      key={dictionary.dictionaryID}
                      trigger="click"
                      placement="right"
                      rootClose={true}
                      overlay={
                        <Popover onClick={preventClick}>
                          <div className="d-flex m-1" role="group">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  Add child dictionary
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-sm text-primary" onClick={() => handleAction(dictionary, onAdd)}>
                                <i className="fa fa-plus"></i>
                              </button>
                              </OverlayTrigger>
                            <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                      <Tooltip>
                                          Sync dictionary
                                      </Tooltip>
                                  }
                            >
                                  <button type="button" className="btn btn-sm text-primary" onClick={() => handleAction(dictionary, onSync)}>
                                      <i className="fa fa-refresh"></i>
                                  </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  Edit dictionary
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-sm text-primary" onClick={() => handleAction(dictionary, onEdit)}>
                                <i className="fa fa-edit"></i>
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip>
                                  Delete dictionary
                                </Tooltip>
                              }
                            >
                              <button type="button" className="btn btn-sm text-primary" onClick={() => handleAction(dictionary, onRemove)} disabled={hasChildren}>
                                <i className="fa fa-trash"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        </Popover>
                      }
                    >
                      <div
                        key={`options-${dictionary.dictionaryID}`}
                        className="d-flex align-items-center justify-content-center rounded-circle text-primary hover-darker"
                        style={{width:'2rem', height:'2rem'}}
                        onClick={preventClick}
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </div>
                    </OverlayTrigger>
                  }
                </div>
                <div className="d-flex px-2 pb-1 justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <FlagIcon code={dictionary.dictionaryCode} />
                    <span className="small text-secondary">{dictionary.termsCount} terms</span>
                    {!dictionary.termsCount && <span className="ps-2 small text-secondary"><i className="fa fa-frown-o"></i></span>}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div id={`${dictionary.dictionaryID}-children`}>
        {hasChildren && dictionary.children.sort((a, b) => a.dictionaryName.localeCompare(b.dictionaryName)).map(dictionary => (
          <DictionaryLeaf key={dictionary.dictionaryID} dictionary={dictionary} onRemove={onRemove} onAdd={onAdd} onSync={onSync} onEdit={onEdit} onViewTerms={onViewTerms}/>
        ))}
      </div>
    </div>
  );
};

export const DictionariesTree = ({ dictionaries, onRemove, onAdd, onSync, onEdit, onViewTerms }) => {
  const { isLoadingBrandDictionaries } = useBrandDictionariesQuery();

  if (isLoadingBrandDictionaries) {
    return (
      <>
        <span className="spinner-border spinner-border-sm text-primary mx-2" role="status" />
        <span className="small text-secondary">Loading dictionaries...</span>
      </>
    )
  }

  if (!isLoadingBrandDictionaries && !dictionaries.length) {
    return (
      <div className="small text-secondary text-center">There is no dictionary for this brand</div>
    )
  }

  return (
    <div id="dictionaries-tree">
      {Boolean(dictionaries.length) && dictionaries.sort((a, b) => a.dictionaryName.localeCompare(b.dictionaryName)).map(dictionary => (
        <DictionaryLeaf key={dictionary.dictionaryID} dictionary={dictionary} onRemove={onRemove} onAdd={onAdd} onSync={onSync} onEdit={onEdit} onViewTerms={onViewTerms}/>
      ))}
    </div>
  );
}