import React, { useState } from 'react';
import { useMutation } from "react-query";
import Swal from 'sweetalert2';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { deleteTerm } from "../../../services/terms.service";

export const RemoveTermModal = ({ term, show, closeModal, onAccept }) => {
  const appInsights = useAppInsightsContext();
  const [ deleting, setDeleting ] = useState(false); 

  const removeTerm = useMutation(deleteTerm);

  const handleDelete = () => {
    setDeleting(true);
    removeTerm.mutate(term, {
      onSuccess: () => {
        Swal.fire({
          text: 'The term was successfully deleted.',
          icon: 'success',
          showConfirmButton: false,
         timer: 1500,
        });
        onAccept();
      },
      onError: (error) => {
        Swal.fire({
          text: 'The term could not be deleted.',
          icon: 'error'
        });
        appInsights.trackException({ exception: error });
      },
      onSettled: () => {
        setDeleting(false);
      },
    });
  };

  return (
    <Modal show={show}>
      <ModalHeader className="fs-4">
        Delete Term
      </ModalHeader>
      <ModalBody>
        Are you certain you want to delete the term <strong>"{term?.value}"</strong>?
      </ModalBody>
      <ModalFooter>
        <button className="btn text-primary" onClick={closeModal} disabled={deleting}>No</button>
        <button className="btn btn-primary" onClick={handleDelete} disabled={deleting}>
          {deleting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};
