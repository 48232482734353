import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { AppContext } from '../providers/app-provider';
import { getDictionariesByBrand } from '../services/dictionaries.service';

export const useBrandDictionariesQuery = () => {
  const appInsights = useAppInsightsContext();
  const { brandId, setDictionaries } = useContext(AppContext);

  const { isLoading: isLoadingBrandDictionaries } = useQuery(
    ['brandDictionaries', brandId],
    getDictionariesByBrand,
    {
      retry: false,
      enabled: brandId !== '0',
      onError: (error) => {
        setDictionaries([]);
        appInsights.trackException({ exception: error });
      },
      onSuccess: (data) => {
        setDictionaries(data);
      },
    }
  );

  return { isLoadingBrandDictionaries };
};
