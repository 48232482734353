import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useIntercom } from 'react-use-intercom';
import { handleResponse } from '../utils/handle-response';
import jwt from 'jwt-decode';

import { AppContext } from './app-provider';
import { Env } from '../constants/env';
import { Role } from '../constants/role';
import { getUser } from '../services/users.service';
import CryptoJS from 'crypto-js';

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const { boot, shutdown } = useIntercom();

  const [displayName, setDisplayName] = useState('');
  const [loadingDisplayName, setLoadingDisplayName] = useState(false);
  const [user, setUser] = useState({
    currentUser: null,
    role: [],
  });

  const parseUser = useCallback((x) => {
    const currentUser = {
      ...x,
      role: Array.isArray(x.role) ? x.role : [x.role],
    };
    return {
      currentUser,
      role: Array.isArray(x.role) ? x.role : [x.role],
      username: localStorage.getItem('currentUserName'),
    };
  }, []);

  const getUserData = (userName) => {
    setLoadingDisplayName(true);
    getUser(userName)
      .then((response) => {
        setDisplayName(response?.data?.displayName || '');
        const roles = response?.data?.roles.map((role) => role.name);
        if (roles.length) {
          setUser((prevState) => ({
            ...prevState,
            role: roles,
          }));
        }

        startIntercom(response?.data?.displayName || '');
      })
      .finally(() => {
        setLoadingDisplayName(false);
      });
  };

  const { clearAppContext } = useContext(AppContext);

  function startIntercom(userDisplayName){

    const userLocal = JSON.parse(localStorage.getItem('currentUser'));

    if(userLocal){
      const userID = `${userLocal.sub}_${userLocal.email}`;
      var hash = CryptoJS.HmacSHA256(userID, Env.IntercomAppVerificationSecret);
      var hmacDigest = CryptoJS.enc.Hex.stringify(hash);

      const bootProps ={
        userId: userID,
        email: userLocal.email,
        name: userDisplayName,
        userHash: hmacDigest
      }; 

      boot(bootProps);
    }
  }

  useEffect(() => {
    const userLocal = JSON.parse(localStorage.getItem('currentUser'));
    if (userLocal) {
      setUser(parseUser(userLocal));
    }
    const userName = localStorage.getItem('currentUserName');
    if (userName) {
      getUserData(userName);
    }
  }, [setUser, parseUser]);

  const authContext = {
    user,
    setUser,
    parseUser,
    displayName,
    loadingDisplayName,
    login: (userName, password) => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userName, password }),
      };

      return fetch(Env.BasePath + 'Account/login', requestOptions)
        .then(handleResponse)
        .then((response) => {
          const token = JSON.parse(response);

          localStorage.setItem('currentUserName', userName);
          localStorage.setItem('token', token.accessToken);
          localStorage.setItem('refreshToken', token.refreshToken);

          const userInfo = jwt(token.accessToken);
          userInfo['token'] = token.accessToken;
          userInfo['refreshToken'] = token.refreshToken;

          setUser(parseUser(userInfo));
          const currentUser = JSON.stringify(userInfo);
          
          localStorage.setItem('currentUser', currentUser);

          getUserData(userName);
        });
    },
      loginfromTalkoot: (loginToken, refreshToken, userName, brandIdIdp) => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUserName');
      localStorage.removeItem('currentBrandIdIdp');
      localStorage.removeItem('currentCustomerId');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');

      localStorage.setItem('currentUserName', userName);
      localStorage.setItem('currentBrandIdIdp', brandIdIdp);
      localStorage.setItem('refreshToken', refreshToken);
      const token = loginToken;
      const user = jwt(token);
      user['token'] = token;

      setUser(parseUser(user));
      const userjson = JSON.stringify(user);

      localStorage.setItem('currentUser', userjson);
      localStorage.setItem('token', token);

      getUserData(userName);
    },
    logout: () => {
      queryClient.clear();
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUserName');
      localStorage.removeItem('currentBrandIdIdp');
      localStorage.removeItem('currentCustomerId');
      localStorage.removeItem('token');
      setUser({
        currentUser: null,
        isAdmin: false,
        isUser: false,
      });
      clearAppContext();

      shutdown();
    },
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};
