import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { PrivateRoute } from './components/common/private-route.component';
import { DictionariesPage } from './components/dictionaries/dictionaries-page.component';
import { UsersPage } from './components/users/users-page.component';
import { PasswordChangePage } from './components/password-change/password-change-page.component';
import { VerifyToken } from './components/common/verify-token.component';
import { Login } from './components/login/login.component';
import { Role } from './constants/role';

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path="/dictionaries" roles={[Role.User, Role.Admin, Role.SuperUser, Role.ReadOnly]} component={DictionariesPage} />
      <PrivateRoute path="/users" roles={[Role.Admin, Role.SuperUser]} component={UsersPage} />
      <Route path="/change-password" component={PasswordChangePage} />
      <Route path="/token" component={VerifyToken} />
      <Route path="/login" component={Login} />
      <Route exact path="/">
        <Redirect to="/dictionaries" />
      </Route>
    </Switch>
  )
};
