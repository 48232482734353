import React, { useCallback, useState } from 'react';
import { debounce } from '../../utils/debounce';

export const SearchInput= ({ placeholder, setSearchTerm, disabled }) => {
  const [ searchInput, setSearchInput ] = useState('');

  const optimizedSetSearchTerm = useCallback(
    debounce((searchTerm) => setSearchTerm(searchTerm)),
    [debounce, setSearchTerm]
  );

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    optimizedSetSearchTerm(value);
  };

  const handleCleanSearch = () => {
    setSearchInput('');
    setSearchTerm('');
  };

  return (
    <div className="position-relative">
      <input
        type="text"
        placeholder={placeholder || 'Search...'}
        className="form-control"
        style={{paddingLeft: '2rem', paddingRight: '2rem'}}
        value={searchInput}
        onChange={handleSearch}
        disabled={disabled}
      />
      <div className="position-absolute top-0 start-0 h-100 d-flex align-items-center px-2">
        <i className="fa fa-search text-tertiary"></i>
      </div>
      {Boolean(searchInput) && <div className="position-absolute top-0 end-0 h-100 d-flex align-items-center px-2">
        <i className="fa fa-remove text-secondary cursor-pointer p-2" onClick={handleCleanSearch}></i>
      </div>}
    </div>
  );
};
