import React from 'react';
import {  useMutation } from 'react-query';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { v4 as uuidv4 } from 'uuid';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { postDictionary } from '../../../services/dictionaries.service';
import { flags } from '../../../constants/flags';
import 'flag-icon-css/css/flag-icons.min.css';

const validationSchema = Yup.object().shape({
  dictionaryName: Yup.string().required('Dictionary name is required'),
});

export const AddChildDictionaryModal = ({ dictionary, show, closeModal, onAccept }) => {
  const appInsights = useAppInsightsContext();
  const addDictionary = useMutation(postDictionary);

    const onSubmit = (values, { setSubmitting, resetForm  }) => {
    addDictionary.mutate(values, {
      onSuccess: () => {
        Swal.fire({
          text: 'The dictionary was successfully created.',
          icon: 'success',
          showConfirmButton: false,
         timer: 1500,
        });
        onAccept({
          ...values,
          dictionaryID: uuidv4(),
          children: [],
        });
            resetForm();
            
      },
      onError: (errorMessage) => {
        Swal.fire({
          text: errorMessage,
          icon: 'error'
        });
          setSubmitting(false);
          resetForm();
          appInsights.trackException({ exception: error });
        
      },
      onSettled: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <Formik
      initialValues={{
        dictionaryName: '',
        dictionaryCode: dictionary.dictionaryCode,
        parentID: dictionary.dictionaryID,
        brandID: dictionary.brandId,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        handleChange,
        handleSubmit,
        isValid,
      }) => (
        <Modal show={show}>
          <ModalHeader>
            <span className="fs-4">Add child dictionary</span>
            <span className={'flag-icon flag-icon-' + flags[dictionary.dictionaryCode]} style={{width: '32px', height: '24px'}}></span>
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <div className="row mb-3">
                <div className="col">
                  <label className="small text-secondary">Parent Dictionary</label>
                  <Field type="text" className="form-control" value={dictionary.dictionaryName} disabled />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className="small text-secondary">Name</label>
                  <Field name="dictionaryName" type="text" className="form-control"  onChange={handleChange} disabled={isSubmitting} />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn text-primary" onClick={closeModal} disabled={isSubmitting}>Cancel</button>
            <button type="submit" className="btn btn-primary" disabled={!isValid && isSubmitting} onClick={handleSubmit}>
              {isSubmitting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
              Save
            </button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  )
};
