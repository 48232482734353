import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist";
import axios from 'axios';
import { Env } from '../../constants/env';

const apiUrl = Env.BasePath;

export const PasswordChangePage = () => {

    const [password, setPassword] = useState([]);
	const [passwordAgain, setPasswordAgain] = useState([]);
    const [passwordValid, setPasswordValid] = useState(false);

    const useQuery= () => {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    const user = query.get('userName');

    const [selectedUser, setSelectedUser]=useState({
        userName: user,
        oldPassword: '',
        password: '' 
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setSelectedUser({
            ...selectedUser,
            [name]: value
        });

        if(name === "password"){
            setPassword(value);
        }

        if(name === "passwordConfirm"){
            setPasswordAgain(value);
        }
    }

    function SubmitButton(){
        if (selectedUser.oldPassword && passwordValid){
          return <button className="btn btn-success" onClick={()=>updatePassword()}>Save</button>
        } else {
          return <button className="btn btn-success" disabled onClick={()=>updatePassword()}>Save</button>
        };
    };

    function Cancel(){
        window.location.href = Env.BasePath+"login";
    };

    const updatePassword = async()=> {

        await axios.put(apiUrl+ 'Account/UpdatePassword', selectedUser)
        .then(response=>{
            var resp = response.data.result;

            if (resp === "Update Success"){
                console.log("Password updated successfully. Redirectioning... Please wait...", 'success'); 
                setTimeout(() => {
                    window.location.href = Env.BasePath;
                }, 1500);
            } else {
                console.log(resp, 'error'); 
            }
        }).catch(error=>{
            console.error(error);
        });
    }

    return (
        <div className="container" style={{marginTop: 100, width: '40%'}}>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <h5>Update Password</h5>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12 form-group d-none">
                            <label className="control-label">User</label>
                            <input name="user" type="text" readOnly value={selectedUser && selectedUser.userName } className="form-control" />
                        </div>
                        <div className="col-md-12 form-group">
                            <label className="control-label">Current Password</label>
                            <input name="oldPassword" type="password" className="form-control" onChange={handleChange} />
                        </div>
                        <div className="col-md-12 form-group">
                            <label className="control-label">New Password</label>
                            <input name="password" type="password" onChange={handleChange} className="form-control" />
                        </div>
                        <div className="col-md-12 form-group">
                            <label className="control-label">Confirm Password</label>
                            <input name="passwordConfirm" type="password" onChange={handleChange} className="form-control" />
                        </div>
                        <div className="col-md-12 form-group">
                            <PasswordChecklist
				                rules={["minLength","specialChar","number","capital","match"]}
				                minLength={6}
				                value={password}
				                valueAgain={passwordAgain}
				                onChange={(isValid) => {setPasswordValid(isValid)}}
			                />
                        </div>
                        <div className="col-md-12">
                            <SubmitButton/>
                            <button className="btn btn-danger" onClick={()=>Cancel()}>Cancel</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}