const doesNotContain = (array, item, property) => {
  return !(array.map(i => i[property]).includes(item[property]));
}
export const createDictionaryTree = (dataset = []) => {
  const hashTable = Object.create(null);
  dataset.forEach(aData => hashTable[aData.dictionaryID] = {...aData, children: []});
  const dataTree = [];
  dataset.forEach(aData => {
    if (aData.parentID) {
      const parentDictionary = dataset.filter(item => item.dictionaryID === aData.parentID)[0];
      if (parentDictionary) {
        const parentDictionaryName = parentDictionary['dictionaryName'];
        hashTable[aData.dictionaryID] = {...hashTable[aData.dictionaryID], parentDictionaryName }
        if (doesNotContain(hashTable[aData.parentID].children, aData, 'dictionaryID')) {
          hashTable[aData.parentID].children.push(hashTable[aData.dictionaryID]);
        }
      }
    } else {
      if (doesNotContain(dataTree, aData, 'dictionaryID')) {
        dataTree.push(hashTable[aData.dictionaryID]);
      }
    }
  });
  return dataTree;
};
