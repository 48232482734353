import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import PasswordChecklist from 'react-password-checklist';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { AppContext } from '../../../providers/app-provider';

import { postUser } from '../../../services/users.service';
import { getBrands } from '../../../services/brands.service';
import { getRoles } from '../../../services/roles.service';

import { useCustomersQuery } from '../../../hooks/use-customers-query.hook';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  userName: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  passwordAgain: Yup.string().required('Confirm password is required'),
  role: Yup.string()
    .required('Role is required')
    .notOneOf(['Select a role...']),
});

export const AddUserModal = ({ show, closeModal, onAccept }) => {
  const { customerId } = useContext(AppContext);
  const [passwordValid, setPasswordValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [initialValues, setInitialValues] = useState({
    userName: '',
    email: '',
    password: '',
    passwordAgain: '',
    role: 'READ-ONLY',
    customerId: '',
    brandId: '',
  });

  const addUser = useMutation(postUser);

  const { isLoading: isLoadingRoles, data: roles } = useQuery(
    ['roles'],
    getRoles
  );

  const { isLoading: isLoadingBrands, data: brands } = useQuery(
    ['brands'],
    getBrands
  );

  const { isLoadingCustomers, customers } = useCustomersQuery();

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    addUser.mutate(values, {
      onSuccess: (response) => {
        const success = response?.data?.succeeded;
        if (success) {
          Swal.fire({
            text: 'User created successfully',
            icon: 'success',
            showConfirmButton: false,
           timer: 1500,
          });
          onAccept();
          resetForm();
        } else {
          Swal.fire({
            text: response.data.errors[0].description,
            icon: 'error',
          });
        }
      },
      onError: () => {
        Swal.fire({
          text: 'User was not created',
          icon: 'error',
        });
      },
      onSettled: () => {
        setSubmitting(false);
        setTouched(false);
      },
    });
  };

  useEffect(() => {
    if (customerId !== '0') {
      setInitialValues((prevState) => ({
        ...prevState,
        customerId,
      }));
    }
  }, [customerId]);

  useEffect(() => {
    if (show && brands?.length && customerId && customerId !== '0') {
      const defaultBrand = (brands|| []).filter(brand => brand.customerID == customerId)[0];
      if (defaultBrand) {
        setInitialValues((prevState) => ({
          ...prevState,
          brandId: defaultBrand.brandID,
        }));
      }
    }
  }, [show, brands, customerId]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleChange, handleSubmit, isValid, values, resetForm }) => (
        <Modal show={show} size='lg'>
          <ModalHeader className='fs-4'>Add User</ModalHeader>
          <ModalBody>
            <div className='form-group'>
              <div className='row mb-3'>
                <div className='col'>
                  <label className='small text-secondary'>Username</label>
                  <Field
                    name='userName'
                    type='text'
                    className='form-control'
                    autoComplete='off'
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </div>
                <div className='col'>
                  <label className='small text-secondary'>Email</label>
                  <Field
                    name='email'
                    type='text'
                    className='form-control'
                    autoComplete='off'
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <label className='small text-secondary'>Password</label>
                  <Field
                    name='password'
                    type='password'
                    className='form-control'
                    autoComplete='new-password'
                    onChange={handleChange}
                    disabled={isSubmitting}
                    onBlur={() => setTouched(true)}
                  />
                </div>
                <div className='col'>
                  <label className='small text-secondary'>
                    Password Confirm
                  </label>
                  <Field
                    name='passwordAgain'
                    type='password'
                    className='form-control'
                    autoComplete='new-password'
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              {touched && (
                <PasswordChecklist
                  className='small mb-3'
                  rules={[
                    'minLength',
                    'specialChar',
                    'number',
                    'capital',
                    'match',
                  ]}
                  minLength={6}
                  value={values.password}
                  valueAgain={values.passwordAgain}
                  onChange={(isValid) => {
                    setPasswordValid(isValid);
                  }}
                />
              )}
              <div className='row mb-3'>
                <div className='col-4'>
                  <label className='small text-secondary'>Role</label>
                  <Field
                    name='role'
                    as='select'
                    className='form-select'
                    onChange={handleChange}
                    disabled={isLoadingRoles || isSubmitting}
                  >
                    {roles &&
                      roles.filter(role => ['READ-ONLY', 'USER', 'SUPERUSER'].includes(role.normalizedName)).map((role) => (
                        <option key={role.name} value={role.normalizedName}>
                          {role.name}
                        </option>
                      ))}
                  </Field>
                </div>
                {!(
                  values.role === 'ADMIN' || values.role === 'Select a role...'
                ) && (
                  <>
                    <div className='col'>
                      <label className='small text-secondary'>Customer</label>
                      <Field
                        name='customerId'
                        as='select'
                        className='form-select'
                        onChange={e => {
                          const defaultBrand = (brands|| []).filter(brand => brand.customerID == e.target.value)[0];
                          if (defaultBrand) {
                            setInitialValues({
                              ...values,
                              brandId: defaultBrand.brandID,
                              customerId: e.target.value,
                            });
                          }
                        }}
                        disabled={isLoadingCustomers || isSubmitting}
                      >
                        {customers &&
                          customers.map((customer) => (
                            <option
                              key={customer.customerID}
                              value={customer.customerID}
                            >
                              {customer.companyName}
                            </option>
                          ))}
                      </Field>
                    </div>
                    <div className='col'>
                      <label className='small text-secondary'>Brand</label>
                      <Field
                        name='brandId'
                        as='select'
                        className='form-select'
                        onChange={handleChange}
                        disabled={
                          isLoadingBrands ||
                          isSubmitting ||
                          values.customerId === 'Select a customer...'
                        }
                      >
                        {brands &&
                          brands
                            .filter(
                              (brand) => brand.customerID == values.customerId
                            )
                            .map((brand) => (
                              <option key={brand.brandID} value={brand.brandID}>
                                {brand.brandName}
                              </option>
                            ))}
                      </Field>
                    </div>
                  </>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className='btn text-primary'
              onClick={() => {
                resetForm();
                setTouched(false);
                closeModal();
              }}
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={!(passwordValid && isValid) || isSubmitting}
              onClick={handleSubmit}
            >
              {isSubmitting && (
                <span
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              )}
              Save
            </button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
};
