import React from 'react';
import { useQuery, useMutation } from "react-query";
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { patchUser } from '../../../services/users.service';
import { getRoles } from '../../../services/roles.service';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  userName: Yup.string().required('Username is required'),
  roleName: Yup.string().notOneOf(['Select a role...']),
});

export const EditUserModal = ({ selectedUser, show, closeModal, onAccept }) => {
  const editUser = useMutation(patchUser);

  const { isLoading: isLoadingRoles, data: roles } = useQuery(
    ['roles'],
    getRoles,
  );

  const onSubmit = (values, { setSubmitting }) => {
    const userData =  { ...selectedUser };
    userData.userName = values.userName;
    userData.roleName = values.roleName;
    userData.email = values.email;
    editUser.mutate(userData, {
      onSuccess: (response) => {
        const success = response?.data.succeeded;
        if (success) {
          Swal.fire({
            text: 'User updated successfully',
            icon: 'success',
            showConfirmButton: false,
           timer: 1500,
          });
          onAccept();
        } else {
          Swal.fire({
            text: response.data.errors[0].description,
            icon: 'error',
          });
        }
      },
      onError: () => {
        Swal.fire({
          text: 'User was not updated',
          icon: 'error'
        });
      },
      onSettled: ()  => {
        setSubmitting(false);
      },
    });
  };

  return (
    <Formik
      initialValues={{
        userName: selectedUser.userName,
        email: selectedUser.email,
        roleName: selectedUser.roleName,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        handleChange,
        handleSubmit,
        isValid,
      }) => (
        <Modal show={show} size="lg">
          <ModalHeader className="fs-4">Edit User</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <div className="row mb-3">
                <div className="col">
                  <label className="small text-secondary">Username</label>
                  <Field name="userName" type="text" className="form-control"  onChange={handleChange} disabled={isSubmitting} />
                </div>
                <div className="col">
                  <label className="small text-secondary">Email</label>
                  <Field name="email" type="text" className="form-control"  onChange={handleChange} disabled={isSubmitting} />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">
                  <label className="small text-secondary">Role</label>
                  <Field name="roleName" as="select" className="form-select" onChange={handleChange} disabled={isLoadingRoles || isSubmitting}>
                    <option value="Select a role..." disabled>Select a role...</option>
                    {roles && roles.filter(role => ['READ-ONLY', 'USER', 'SUPERUSER'].includes(role.normalizedName)).map(role => (
                      <option key={role.name} value={role.name} >{role.name}</option>
                    ))}
                  </Field>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn text-primary" onClick={closeModal} disabled={isSubmitting}>Cancel</button>
            <button type="submit" className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={handleSubmit}>
              {isSubmitting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
              Save
            </button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  )
};
 