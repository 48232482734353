import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import { AuthContext } from "../../providers/auth-provider"

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { user: { currentUser, role } } = useContext(AuthContext);
  return (
    <Route {...rest} render={props => {
      if (!currentUser) {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
      if (rest?.path !== '/dictionaries' && !(roles.some(r => role.includes(r)))) {
        return <Redirect to={{ pathname: '/dictionaries'}} />
      }
      return <Component {...props} />
    }} />
  )
};
