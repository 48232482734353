import { useQuery } from 'react-query';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { getUsersByCustomer } from '../services/users.service';
import { useContext } from 'react';
import { AppContext } from '../providers/app-provider';

export const useCustomerUsersQuery = () => {
  const appInsights = useAppInsightsContext();
  const { customerId } = useContext(AppContext);

  const { isLoading: isLoadingCustomerUsers, data: customerUsers } = useQuery(
    ['customerUsers', customerId],
    getUsersByCustomer,
    {
      retry: false,
      enabled: customerId !== '0',
      onError: (error) => {
        appInsights.trackException({ exception: error });
      },
    }
  );

  return { isLoadingCustomerUsers, customerUsers };
};
