export const flags = (code) => {
  const flagsByCode = {
    "en-us": "us",
    "en-gb": "gb",
    "it-it": "it",
    "fr-fr": "fr",
    "es-sp": "es",
    "es-mx": "mx",
    "de-de": "de",
    "nl-nl": "nl",
    "pt-br": "br",
    "pt-pt": "pt",
    "sv-se": "se",
    "da-dk": "dk",
    "pl": "pl",
    "ja-jp": "jp",
    "en-au": "au",
    "en-ca": "ca",
    "en-za": "za",
    "no-nb": "no",
    "ar-ae": "ae",
    "bg-bg": "bg",
    "cs-cz": "cz",
    "el-gr": "gr",
    "et-ee": "ee",
    "fi-fi": "fi",
    "hu-hu": "hu",
    "id-id": "id",
    "ko-kr": "kr",
    "lt-lt": "lt",
    "lv-lv": "lv",
    "ro-ro": "ro",
    "ru-ru": "ru",
    "sk-sk": "sk",
    "sl-si": "si",
    "tr-tr": "tr",
    "uk-ua": "ua",
    "fr-ca": "ca"
  };
  return flagsByCode[code];
};
