import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { AppContext } from '../providers/app-provider';
import { getBrandsByCustomerIdAndUserName } from '../services/brands.service';

export const useCustomerBrandsQuery = () => {
  const appInsights = useAppInsightsContext();
  const { customerId } = useContext(AppContext);  

  const { isLoading: isLoadingCustomerBrands, data: customerBrands } = useQuery(
    ['customerBrands', customerId],
    getBrandsByCustomerIdAndUserName,
    {
      retry: false,
      enabled: customerId !== '0',
      onError: (error) => {
        appInsights.trackException({ exception: error });
      },
    }
  );

  return { isLoadingCustomerBrands, customerBrands };
};
