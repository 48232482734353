import axios from 'axios';
import { Env } from '../constants/env';

const cleanAndLogOut = () => {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserName');
    localStorage.removeItem('currentBrandIdIdp');
    localStorage.removeItem('currentCustomerId');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.href = Env.BasePath;
};

const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem('token');
  if (!accessToken || accessToken === null) {
    cleanAndLogOut();
  }
  return accessToken;
};

const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken || refreshToken === null) {
    cleanAndLogOut();
  }
  return refreshToken;
};

const getRefreshToken = () => {
  return axios.post(`${Env.BasePath}account/refreshToken?refreshToken=${getLocalRefreshToken()}`);
}

export const jwtInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = getLocalAccessToken();
      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => response,
    async (err) => {
      const config = err?.config;
      if (err.response) {
        // Access Token was expired
        if (err.response?.status === 401 && !config?._retry) {
          config._retry = true;
          try {
            const rs = await getRefreshToken();
            const { accessToken, refreshToken } = rs.data;
            window.localStorage.setItem('token', accessToken);
            window.localStorage.setItem('refreshToken', refreshToken);
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${accessToken}`
            }
            return axios(config);
          } catch (_error) {
            if (_error.response && _error.response.data) {
              cleanAndLogOut();
            }
            return Promise.reject(_error);
          }
        } 
        if (err.response.status === 403 && err.response.data) {
          return Promise.reject(err.response.data);
        }
      }
        return err?.response?.data ? Promise.reject(err.response.data) : Promise.reject(err);
    }
  );
};
