import React, { useState } from 'react';
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { deleteDictionary } from '../../../services/dictionaries.service';

export const RemoveDictionaryModal = ({ dictionary, show, closeModal, onAccept }) => {
  const appInsights = useAppInsightsContext();
  const [ deleting, setDeleting ] = useState(false); 

  const removeDictionary = useMutation(deleteDictionary);

  const handleDelete = () => {
    setDeleting(true);
    removeDictionary.mutate(dictionary, {
      onSuccess: () => {
        Swal.fire({
          text: 'The dictionary was successfully deleted',
          icon: 'success',
          showConfirmButton: false,
         timer: 1500,
        });
        onAccept(dictionary);
      },
      onError: (error) => {
        Swal.fire({
          text: 'The dictionary was not deleted',
          icon: 'error'
        });
        appInsights.trackException({ exception: error });
      },
      onSettled: () => {
        setDeleting(false);
      },
    });
  };

  return (
    <Modal show={show}>
      <ModalHeader className="fs-4">
        Delete Dictionary
      </ModalHeader>
      <ModalBody>
        Are you certain you want to delete the dictionary <strong>"{dictionary?.dictionaryName}"</strong>?
      </ModalBody>
      <ModalFooter>
        <button className="btn text-primary" onClick={closeModal} disabled={deleting}>No</button>
        <button className="btn btn-primary" onClick={handleDelete} disabled={deleting}>
          {deleting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};
