import React, { useState } from 'react';
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { syncDictionary } from '../../../services/dictionaries.service';

export const SyncDictionaryModal = ({ dictionary, show, closeModal, onAccept }) => {
    const appInsights = useAppInsightsContext();
    const [syncing, setSyncing] = useState(false);

    const syncedDictionary = useMutation(syncDictionary);

    const handleSync = () => {
        setSyncing(true);
        syncedDictionary.mutate(dictionary, {
            onSuccess: () => {
                Swal.fire({
                    text: 'The dictionary was successfully synced',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
                onAccept(dictionary);
            },
            onError: (error) => {
                Swal.fire({
                    text: 'The dictionary was not synced',
                    icon: 'error'
                });
                appInsights.trackException({ exception: error });
            },
            onSettled: () => {
                setSyncing(false);
            },
        });
    };

    return (
        <Modal show={show}>
            <ModalHeader className="fs-4">
                Sync Dictionary
            </ModalHeader>
            <ModalBody>
                Are you want to sync the dictionary <strong>"{dictionary?.dictionaryName}"</strong>?
            </ModalBody>
            <ModalFooter>
                <button className="btn text-primary" onClick={closeModal} disabled={syncing}>No</button>
                <button className="btn btn-primary" onClick={handleSync} disabled={syncing}>
                    {syncing && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                    Yes
                </button>
            </ModalFooter>
        </Modal>
    );
};
